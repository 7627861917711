.holidays {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    .holiday {
        flex-basis: 10%;
    }
}

.holidays-fieldset {
    font-size: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .holidays-legend {
        font-size: 1rem;
    }
}
