.holidays-table {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    .holiday {
        flex-basis: 14.2%;
    }
}

.holidays-fieldset {
    font-size: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 8px 16px;
    .holidays-legend {
        font-size: 1rem;
    }
}

.holidays-wrapper {
    padding: 15px;
}
