@import '../../content/scss/variables.scss';

.navbar {
    a {
        display: block;
        line-height: inherit;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    }
}

.nav__menu {
    display: inline-block;
    position: relative;
    min-width: 220px;
    &:hover {
        .nav__sub_menu {
            display: flex;
            flex-wrap: wrap;
        }
    }
    @media screen and (max-width: 1280px) {
        min-width: 150px;
    }
}

.nav__sub_menu {
    font-weight: 300;
    text-transform: none;
    display: none;
    position: absolute;
    z-index: 100;
    width: $submenu-width;
    list-style-type: none;
    background-color: $nav-bar-bg-color;
    padding: 0;
    border-radius: 5px;
    cursor: pointer;
    a {
        line-height: 40px;
    }
}

.nav__submenu-item {
    padding: 0.5rem 1rem;
    flex-basis: 50%;

    &:hover {
        background-color: $color-gray;
    }
    @media screen and (max-width: 1280px) {
        min-width: 150px;
    }
}

.navbar-tooltip {
    .nav__sub_menu {
        width: 236px;
        .nav__submenu-item {
            flex-basis: 100%;
        }
    }
}
