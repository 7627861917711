.class-data-grid {
    .rdg-cell-action {
        &.rdg-cell-action-last {
            margin-right: -8px;
            float: right;
        }
    }
}

.clazz-holiday {
    .holidays {
        .holiday {
            flex-basis: 14.2%;
        }
    }
}
