@import '../../../../content/scss/variables.scss';
.rdg-editor-container {
    select {
        &.editor-main {
            padding: 2px !important;
        }
    }
}

.frozen-header {
    display: flex;
    align-items: center;
    height: 100%;
}

.day-off-header {
    height: 52px;
    text-align: center;
    width: 100%;
    .weekday {
        border-top: 1px solid black;
    }
}

.empty-cell {
    height: 24px;
}

.saturday {
    background-color: $color-yellow-emphasis;
}

.sunday {
    background-color: $color-red-emphasis;
}
