@import 'node_modules/bootstrap/scss/bootstrap';

$grid-breakpoints: (
    xxxs: 0,
    xxs: 320px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px,
);

$submenu-width: 440px;
$nav-bar-bg-color: #f8f9fa;

$color-gray: #e9ecef;
$color-yellow-emphasis: rgba(255, 242, 204, 0.5);
$color-red-emphasis: rgba(244, 204, 204, 0.5);
$color-green-emphasis: rgba(132, 244, 177, 0.642);
$color-orange-emphasis: rgba(252, 182, 97, 0.7);
$color-blue-emphasis: rgba(91, 162, 243, 0.748);
