@import './content/scss/variables.scss';
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

// react datepicker
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    z-index: 1000;
}

// react loading overlay
._loading_overlay_wrapper {
    height: 100%;
}

._loading_overlay_overlay {
    z-index: 1400 !important;
}

// GLOBAL STYLES
.is-fullheight {
    height: 100%;
}

.is-fullwidth {
    width: 100%;
}

.fmi-label {
    font-weight: 600;
}

// React-grid-data
.react-grid-Row:hover .react-grid-Cell {
    background-color: #c9cabf !important;
}

// React dropzone
.container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
}

.container > p {
    font-size: 1rem;
}

.container > em {
    font-size: 0.8rem;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
}

.dropzone:focus {
    border-color: #2196f3;
}

.dropzone.disabled {
    opacity: 0.6;
}

// React select
.fmi-react-select__control {
    &.fmi-react-select__control--is-focused {
        &.fmi-react-select__control--menu-is-open {
            .fmi-react-select__single-value {
                top: 12px;
            }
            .fmi-react-select__placeholder {
                top: 17px;
            }
        }
    }
    .fmi-react-select__value-container--has-value {
        .fmi-react-select__single-value {
            top: 17px;
        }
    }
    &.fmi-react-select__control--is-focused {
        &.fmi-react-select__control--menu-is-open {
            .fmi-react-select__value-container--has-value {
                .fmi-react-select__single-value {
                    top: 17px;
                }
            }
        }
    }
}
.has-default {
    .fmi-react-select__control {
        .fmi-react-select__single-value {
            top: 50%;
        }
    }
}
.fmi-react-select__indicators {
    .fmi-react-select__indicator {
        padding: 2px 8px;
    }
}

.fmi-navbar {
    height: 40px;
}

.fmi-toolbar-button {
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-small {
    font-size: 14px;
}

.day-off-header {
    height: 52px;
    text-align: center;
    .weekday {
        border-top: 1px solid black;
    }
}

.react-grid-HeaderCell--frozen {
    z-index: 2;
}

.align-pdf {
    margin-top: 0.5rem;
    .react-pdf__Page {
        display: flex;
        justify-content: center;
    }
}
