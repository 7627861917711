@import 'content/scss/variables.scss';

.tcdn-nhiemky-table {
    .react-grid-Cell {
        padding: 0;
        .react-grid-Cell__value {
            padding: 8px;
        }
    }
}

.blue {
    background-color: $color-blue-emphasis;
}

.orange {
    background-color: $color-orange-emphasis;
}

.green {
    background-color: $color-green-emphasis;
}
