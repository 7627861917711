.absence-school {
    .react-grid-HeaderCell {
        display: flex !important;
        align-items: center;
    }
    .day-off-header {
        font-size: 0.9rem;
    }
}

.cm-column {
    background-color: #bbdefb;
}

.vm-column {
    background-color: #ffccbc;
}
