.rdg-cell-action {
    display: flex;
    justify-content: center;
    &.rdg-cell-action-last {
        float: none;
        margin-right: 0;
    }
}

.student-data-grid {
    .rdg-cell-action {
        &.rdg-cell-action-last {
            margin-right: -8px;
            float: right;
        }
    }
}
